*{
  box-sizing: border-box;
}

.mobile{
  display: none;
}

.App {
  text-align: center;
  font-family: 'Arial','Helvetica','sans-serif';
}

.App-logo {
  height: 43px;
  height: 5vmin;
  max-height: 43px;
  pointer-events: none;
}

.App-header {
  background-color: #FFF;
  height: 70px;
  height: 8vmin;
  max-height: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title,
.title-result{
  color: #000;
  font-size: calc(8px + 2.4vmin);
  font-family: Georgia;
  font-weight: bold;
  margin: 9vh 0 0;
}
.title::after{
  content: "Veja o quanto você ainda \A pode contribuir para atingir \A seu benefício fiscal.";
  white-space: pre;
}
.title-result::after{
  content: "Veja o quanto você ainda pode contribuir \A no seu plano PGBL para atingir \A o seu benefício fiscal máximo";
  white-space: pre;
}

.Form {
  margin-top: -5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 75vh;
  max-height: 75vh;
  opacity: 1;
  transition: 0.8s;
  overflow: hidden;
}

.passos{
  color: #000;
  font-size: calc(8px + 1vmin);
}

.filler{
  width: calc(50px + 30vw);
  height: calc(8px + 1vmin);
  background-color: #C4C4C4;
  border-radius: calc(4px + 0.5vmin);
  margin: 0 auto;
}

.fill{
  height: calc(8px + 1vmin);
  background-color: #0090DA;
  border-radius: calc(4px + 0.5vmin);
}

.pergunta{
  font-size: calc(8px + 2vmin);
  font-weight: bold;
  margin: 0 10vw;
  white-space: pre-wrap;
}

.sub{
  font-size: calc(8px + 1vmin);
  color: #75787B;
  margin: 0.5em 10vw 0;
}

input{
  margin-top: 0.8em;
  height: calc(25px + 5vmin);
  width: calc(100px + 22vw);
  font-size: calc(10px + 2vmin);
  font-weight: bold;
  text-align: center;
  border: none;
  color: #007ABC;
  border-radius: 5px;
}

input::-webkit-input-placeholder { /* Edge */
  color: #007ABC;
}

input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #007ABC;
}

input::placeholder {
  color: #007ABC;
}

.btns{
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.btns-sim{
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

button{
  height: calc(25px + 5vmin);
  font-size: calc(8px + 1.5vmin);
  text-align: center;
  border-radius: 5px;
  position: relative;
  text-transform: uppercase;
  font-weight: bold;
}

.btns button{
  width: calc(30px + 20vw);
}

.btns-sim button{
  white-space: nowrap;
}
.btn-voltar,
.btn-voltar:active,
.btn-voltar:focus{
  color: #C4C4C4;
  border: 2px solid #C4C4C4;
}

.btn-voltar::after{
  content: "";
  background: url('./seta-last.svg')no-repeat 50% 50%;
  background-size: contain;
  position: absolute;
  height: 40%;
  width: 15px;
  left: calc(12% - 8px);
  top: 30%;

}

.btn-avancar,
.btn-avancar:active,
.btn-avancar:focus{
  color: #007ABC;
  border: 2px solid #007ABC;
}

.btn-avancar::after{
  content: "";
  background: url('./seta-next.svg') no-repeat 50% 50%;
  background-size: contain;
  position: absolute;
  height: 40%;
  width: 15px;
  right: calc(12% - 8px);
  top: 30%;
}

.btn-simular,
.btn-simular:active,
.btn-simular:focus{
  color: #FFF;
  border: 2px solid #FFF;
  background: #007ABC;
}

.btn-simular::after{
  content: "";
  background: url('./seta-go.svg') no-repeat 50% 50%;
  background-size: contain;
  position: absolute;
  height: 40%;
  width: 15px;
  right: calc(12% - 8px);
  top: 30%;
}

.btn-reset,
.btn-reset:active,
.btn-reset:focus{
  color: #007ABC;
  border: 2px solid #007ABC;
  padding: 0 5%;
}

.btn-reset::after{
  content: "";
  background: url('./seta-reset.svg') no-repeat 50% 50%;
  background-size: contain;
  position: absolute;
  height: 40%;
  width: 15px;
  left: calc(8% - 8px);
  top: 30%;
}

.btn-pdf,
.btn-pdf:active,
.btn-pdf:focus{
  color: #FFF;
  border: 2px solid #007ABC;
  background: #007ABC;
  padding: 0 2.5%;
}

.btn-pdf::after{
    content: "GERAR PDF DA SIMULAÇÃO";
}

.Result{
  max-height: 200vh;
  overflow: hidden;
  opacity: 1;
  transition: 0.8s;
}

.Loading{
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: -1;
}

.hidden{
  opacity: 0;
  max-height: 0;
  margin: 0;
  padding: 0;
  transition: 0.8s;
  overflow: hidden;
}

.detalhes{
  padding: 1.2em 10vw;
  background: #FFF;
  max-width: 100%;
}

.detalhes>div{
  position: relative;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  flex-wrap: wrap;
  max-width: 100%;
}

.pad,
.padw{
  padding: 2.5vw 10vw 1.2em;
}

.Sucesso p{
  padding: 10vmin;
  color: #007ABC;
  display: block;
}
.Sucesso p::after{
  content: "Sua simulação foi gerada com sucesso!";
  font-size: calc(10px + 1.5vmin);
  font-weight: bold;
  line-height: 1.5em;
}

.hinfo{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.hinfo>div:first-child{
  color: rgb(0, 122, 188);
  font-size: calc(8px + 1.5vmin);
  font-weight: bold;
}
.hinfo>div:last-child{
  color: rgb(167, 168, 170);
  font-size: calc(8px + 1vmin);
}

.Info,
.Total{
  color: #FFF;
  padding: 1em;
  margin: 1.5em 0 0;
  border-radius: 5px;
  font-size: calc(8px + 1vmin);
}
.Info{
  background: #A4CE4E;
  font-weight: bold;
  font-size: calc(10px + 1vmin);
}
.Total{
  background: #C4C4C4;
  display: flex;
  padding: 1em 0;
  flex-direction: row;
  font-weight: bold;
}
.Total>div{
  padding: 0 1.8em 0 3em;
}

.TblHeader{
  position: relative;
  color: #FFF;
  background: #007ABC;
  padding: 1.2em 1.8em;
  text-align: left;
  border-radius: 5px;
  font-size: calc(8px + 1vmin);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 2;
}

.detalhes ul{
    position: relative;
    background: #F2F2F2;
    margin-top: -5px;
    text-align: right;
    list-style: none;
    font-size: 0.9em;
    font-size: calc(8px + 0.5vmin);
    color: #75787B;
    padding: 15px 1em 1.2em 2em ;
    z-index: 1;
}
.detalhes ul li{
  padding: 0.75vmin 1.2em 0.75vmin 1.2em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Home{
  position: relative;
  background: #FFF;
  overflow: hidden;
}

.Home .top{
  overflow: hidden;
  position: relative;
  text-align: left;
}

.Home img{
  position: absolute;
  object-fit: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.Home .top-text{
  position: relative;
  display: inline-block;
  margin: 5vh auto 5vh 15vw;
  width: auto;
  background: rgba(0, 122, 188, 0.9);
  text-align: left;
  color: #FFF;
  padding: 3vh 5vw 4vh 2.5vw;
  font-size: calc(10px + 1vmin);
  font-weight: bold;
  white-space: nowrap;
  z-index: 10;
  line-height: 1.4em;
}

.Home .top-text h3{
  margin: 0 0 3vh;
  font-family: Georgia;
  font-size: calc(1.5em + 12px);
  line-height: 1.25em;
}

.Home button{
  color: #000;
  background: #A4CE4E;
  border: none;
  border-radius: 0;
  height: auto;
  padding: 0.6em 1.2em;
  text-transform: none;
  font-size: 1em;
}

.video {
  position: relative;
  padding-bottom: 33.75%;
  height: 0;
  overflow: hidden;
  max-width: 60%;
  margin: 3vh auto;
}
.video iframe,
.video object,
.video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.End{
  padding: 1.2em 10vw 3em;
  background: #FFF;
}

.End h3{
  font-family: Georgia;
  font-size: calc(8px + 2vmin);
  text-align: left;
  margin: 2vw 0 4vw;
}

.BlogList{
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: row;
}

.BlogList>div{
  width: 18vw;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}

.BlogList img{
  width: 18vw;
}

.BlogList p{
  flex: 1;
  margin: 2em 0 1.5em;
  font-size: calc(8px + 0.5vmin);
  color: #75787B;
}

.BlogList button{
  font-size: calc(9px + 0.5vmin);
  color: #FFF;
  width: auto;
  height: auto;
  padding: 2vmin 3vmin;
  background: #007ABC;
  border-radius: 0;
  border: none;
  text-transform: none;
}

.s13{
  color: rgb(0, 122, 188);
  font-size: min(1.2vw, calc(8px + 1vmin));
  margin: 2.5vh 0 0;
}

footer{
  text-align: justify;
  font-size: calc(8px + 0.5vmin);
  color: #333;
  padding-left: 20vw;
  background-image: url('./logo.svg');
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 15vw;
}

@media (prefers-reduced-motion: no-preference) {
  .App-loader {
    animation: App-logo-spin infinite 1s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Mobile */
@media (max-width: 767px) {
  .mobile{
    display: block;
  }
  .App-logo {
    height: 8vh;
    max-height: 43px;
    pointer-events: none;
  }
  .App-header {
    background-color: #FFF;
    height: 12vh;
    max-height: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .Home img{
    position: relative;
    width: 100%;
    object-fit: fill;
    height: auto;
    margin-bottom: -5px;
  }
  .Home .top-text{
    position: relative;
    top: 0;
    left: 0;
    margin: 0 0 20px;
    padding: 3vh 0;
    width: 100%;
    height: auto;
    text-align: center;
  }
  .video {
    max-width: 100%;
    padding-bottom: 56.25%;
    margin: 0;
  }
  .title,
  .title-result {
      font-size: calc(16px + 2.4vmin);
      margin: 5vh 0 4vh;
  }
  .title::after{
    content: "Veja o quanto você ainda \A pode contribuir para atingir \A seu benefício fiscal.";
    white-space: pre;
  }
  .pad,
  .padw{
    padding: 4vw 10vw 1.2em;
  }
  .padw{
    background: #FFF;
  }
  .Sucesso p{
    padding: 2vw;
  }
  .Sucesso p::after{
    content: "Sua simulação\A foi gerada com sucesso!";
    white-space: pre;
  }
  .hinfo{
    flex-direction: column;
    height: calc(16px + 7.5vmin);
  }
  .hinfo>div:first-child{
    font-size: calc(8px + 3vmin);
  }
  .Info{
    padding: 1em 2em;
  }
  input{
    width: 100%;
  }
  .btns{
    width: 90%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-around;
  }
  button{
    height: calc(25px + 5vmin);
    font-size: calc(8px + 1.5vmin);
    text-align: center;
    border-radius: 5px;
    position: relative;
    text-transform: uppercase;
    font-weight: bold;
  }
  .btns-sim button,
  .btns button{
    width: 80%;
    margin: 2vh auto;
  }
  .detalhes{
      display: none;
  }
  .Total{
    background: #FFF;
    color: #333;
    padding: 4vw 10vw 1.2em;
    margin: 0;
    flex-direction: column;
  }
  .Total>div{
    width: 80%;
    padding: 1.2em 1.8em;
    margin: 0 auto;
    text-align: center;
  }
  .Total>div:nth-child(even){
    font-size: calc(8px + 4vmin);
    padding: 0.6em 0;
  }
  .btns-sim{
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
  }
  .btn-pdf{
    background: #A4CE4E;
    border: 2px solid #A4CE4E;
    color: #333;
  }
  .btn-pdf::after{
    content: "Receber PDF detalhado";
  }
  .End h3{
    font-size: calc(8px + 4vmin);
  }
  .BlogList{
    flex-direction: column;
  }
  .BlogList>div{
    width: 100%;
    align-items: flex-start;
    margin: 2vmin 0 4vmin;
  }
  .BlogList img{
    width: 100%;
  }
  .BlogList p{
    text-align: left;
    font-size: calc(8px + 1.5vmin);
  }
  .BlogList button{
    font-size: calc(9px + 1vmin);
    padding: 3vmin 4vmin;
  }
  .s13{
    font-size: 2.4vw;
  }
  footer{
    text-align: justify;
    padding-left: 0;
    padding-top: 10vw;
    background-position: 50% 0;
    background-size: 30vw;
  }
}

@media (max-width: 567px) {
  input{
    width: 90%;
  }
}

.pdf *{
  transition: none;
}
.pdf .App-logo{
  height: 43px !important;
}
.pdf .App-header{
  height: 70px !important;
}
.pdf .title,
.pdf .title-result{
  margin-top: 20px !important;
}
.pdf .title::after{
  content: "Veja o quanto você ainda \A pode contribuir para atingir \A seu benefício fiscal.";
  white-space: pre;
}

.pdf .btns-sim{
  height: 0;
  display: none !important;
}
.pdf .detalhes{
  display: block !important;
}
.pdf .mobile{
  display: none !important;
  height: 0 !important;
}
.pdf .hidden{
  height: 0 !important;
  display: none !important;
}
.pfd .Form {
  display: none !important;
  margin-top: 0 !important;
}
.pdf .Loading{
  display: none !important;
  height: 0 !important;
}

.pdf .Total{
  background: #C4C4C4;
  color: #FFF;
  padding: 1em;
  margin: 1.5em 0 0;
  border-radius: 5px;
  font-size: calc(8px + 1vmin);
  display: flex;
  padding: 1em 0;
  flex-direction: row;
  font-weight: bold;
}
.pdf .Total>div{
  padding: 0 1.8em 0 3em;
}
.pdf .Total>div:nth-child(1){
  width: 480px !important;
}
.pdf .Total>div:nth-child(2){
  width: 192px !important;
}
.pdf .Total>div:nth-child(3){
  width: 305px !important;
}
